<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">

            <CRow>
              <CCol sm="12">
                <CCard>
                  <CCardHeader>
                    <CCardTitle>Create a new Customer </CCardTitle>
                  </CCardHeader>

                  <CCardBody>
                    <CRow>
                      <CCol sm="6">
                        <CInput
                          name="name"
                          label="Name"
                          placeholder="Enter the numbers or name of the customers"
                          description="If you separate values with comma's, customers will be duplicated. E.g. 123A,456B will create two customers."
                          v-model="customer.name"
                          required
                        />
                      </CCol>
                      <CCol sm="6">
                        <CSelect v-if="$store.getters.isAdmin"
                          placeholder="Select"
                          label="Location"
                          :options="locations"
                          :value.sync="customer.location_id"
                        />
                        <CInput v-if="!$store.getters.isAdmin"
                            type="hidden"
                            value='false'
                            v-model="customer.location_id"
                        />
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>

                <CCard>

                  <CCardHeader>
                    <CCardSubtitle>Address Information</CCardSubtitle>
                  </CCardHeader>

                  <CCardBody>

                    <CRow>
                      <CCol sm="4">
                        <CInput
                          name="address"
                          label="Address"
                          placeholder="Enter the address of the customer"
                          v-model="customer.address"
                        />
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          label="Zip Code"
                          placeholder="Enter the zip code of the customer"
                          v-model="customer.zip_code"
                        />
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          label="City"
                          placeholder="Enter the city of the customer"
                          v-model="customer.city"
                        />
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>

                <CCard>

                  <CCardHeader>
                    <CCardSubtitle>Contact Information</CCardSubtitle>
                  </CCardHeader>

                  <CCardBody>

                    <CRow>
                      <CCol sm="4">
                        <CInput
                          name="mail"
                          label="Mail"
                          placeholder="Enter the mail of the customer"
                          v-model="customer.mail"
                        />
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          name="telephone"
                          label="Telephone"
                          placeholder="Enter the telephone of the customer"
                          v-model="customer.telephone"
                        />
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>

                <CCol sm="12">
                  <CButton variant="outline" type="submit" color="primary" @click="createCustomer">
                    <CIcon name="cil-check-circle"/>
                    Create
                  </CButton>
                </CCol>

              </CCol>
            </CRow>

      </transition>
    </CCol>
  </CRow>
</template>

<script>

import CustomerAPI from '/app/src/api/customer.js'
import LocationAPI from '/app/src/api/location.js'

export default {
  name: 'Create',
  data: () => {
    return {
      locationAPI: new LocationAPI(),
      locations: [],
      customer: {},
      customerAPI: new CustomerAPI()
    }
  },
  created: function() {
    this.getLocations()
  },
  methods: {
    getLocations: function() {
      this.$store.dispatch('loading')
      let self = this
      self.locationAPI.all()
      .then((result) => {
        this.$store.dispatch('stopLoading')
        for (let i = 0; i < result.length; i++) {
          self.locations.push({'label': result[i].name, 'value': result[i].id})
        }
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
        this.$alert.show({type: 'danger', 'message': error})
      })
    },
    createCustomer: function() {
      this.$store.dispatch('loading')
      let self = this
      this.customerAPI.create(self.customer)
      .then((result) => {
        this.$store.dispatch('stopLoading')
        self.$router.push({path: `/manager/customers/${result.id}` })
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        this.$alert.show({type: 'danger', 'message': error})
      })

    }
  }
}
</script>
